<!--begin::Main-->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <div
    id="kt_header_mobile"
    class="header-mobile"
    [ngClass]="headerMobileClasses"
  >
    <!--begin::Toolbar-->
    <div class="d-flex">
      <!--begin::Aside Mobile Toggle-->
      <button class="btn p-0 burger-icon" id="kt_aside_mobile_toggle">
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Logo-->
      <div>
        <a onclick="history.back()">
          <img alt="Logo" [attr.src]="headerLogo" class="mobilelogo" />
        </a>
      </div>
      <!--end::Logo-->
      <!--end::Topbar Mobile Toggle-->
    </div>
  </div>
  <!--end::Toolbar-->

  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <ng-container>
        <!--begin::STATIC Aside-->
        <div
          #ktAside
          id="kt_aside"
          class="aside aside-light aside-hoverable aside-left d-flex flex-column flex-row-auto"
          [ngClass]="asideCSSClasses"
          *ngIf="!ispublished"
        >
          <!--begin::Brand-->
          <div
            class="brand flex-column-auto"
            id="kt_brand"
            [ngClass]="brandClasses"
          >
            <!--begin::Logo-->
            <a onclick="history.back()" class="brand-logo">
              <img alt="Logo" [attr.src]="headerLogo" class="h-55px logo" />
            </a>
            <!--end::Logo-->

            <ng-container>
              <button
                class="brand-toggle btn btn-icon w-30px h-30px w-md-40px h-md-40px"
                id="kt_aside_toggle"
                (click)="slideToggle()"
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/Navigation/Angle-double-left.svg'
                  "
                  cacheSVG="true"
                  class="svg-icon svg-icon-xl"
                ></span>
              </button>
            </ng-container>
          </div>
          <!--end::Brand-->

          <!--begin::Aside Menu-->
          <div
            class="aside-menu-wrapper flex-column-fluid"
            id="kt_aside_menu_wrapper"
          >
            <ng-container *ngIf="disableAsideSelfDisplay">
              <!--begin::Header Logo-->
              <a onclick="history.back()">
                <img alt="Logo" [attr.src]="headerLogo" />
              </a>
              <!--end::Header Logo-->
            </ng-container>
            <!--begin::Menu Container-->
            <div
              id="kt_aside_menu"
              class="aside-menu"
              [ngClass]="asideMenuCSSClasses"
              data-menu-vertical="1"
              [attr.data-menu-scroll]="asideMenuScroll"
              [attr.data-menu-dropdown]="asideMenuDropdown"
              data-menu-dropdown-timeout="500"
            >
              <div
                class="hover-scroll-overlay-y my-5 my-lg-5"
                id="kt_aside_menu_wrapper"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                data-kt-scroll-wrappers="#kt_aside_menu"
                data-kt-scroll-offset="0"
              >
                <div
                  class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                  id="kt_aside_menu"
                  data-kt-menu="true"
                >
                  <ul class="menu-nav" [ngClass]="ulCSSClasses">
                    <!-- begin::1 Level -->

                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="approval"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              width="22"
                              height="25"
                              viewBox="0 0 24 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="approval"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.837 24.663h14.608v-6.284a5.92 5.92 0 01-.103.007 1.9 1.9 0 00-.18.017c-1.978.309-3.853-.014-5.64-.907a1.236 1.236 0 00-.53-.115c-1.304-.007-2.608-.006-3.912-.006H4.584v-1.871h3.271l-.314-.45c-.265-.38-.525-.75-.772-1.13-.11-.168-.218-.233-.416-.229-.42.01-.84.008-1.267.006l-.508-.001v-1.847h1.283c-.349-1.26-.46-2.499-.298-3.757A9.539 9.539 0 016.77 4.554H1.837v20.109zm12.91-8.005c4.061.01 7.4-3.3 7.413-7.346.012-4.088-3.296-7.456-7.335-7.468-4.14-.013-7.45 3.26-7.47 7.386a7.386 7.386 0 007.391 7.428zm3.605 9.898H0V2.696h.367l1.452.001c2.034 0 4.067.001 6.1-.009a.833.833 0 00.511-.198C10.458.646 12.843-.182 15.563.033c4.14.327 7.592 3.518 8.298 7.612.72 4.18-1.406 8.245-5.252 10.02-.203.094-.262.2-.261.415.006 2.136.005 4.273.005 6.41v2.066zM13.34 13.522L8.986 9.167l1.275-1.27 3.093 3.082 5.4-5.405 1.253 1.276-6.667 6.672zm.366 7.513H4.57v-1.82h9.136v1.82z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Approval</span
                        >
                      </a>
                    </li>
                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="assign"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              width="25"
                              height="28"
                              viewBox="0 0 25 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="approval_with_sign"
                            >
                              <path
                                d="M15.959.09l8.5 6.967a.38.38 0 01.058.548l-1.405 1.658a.403.403 0 01-.558.048h.001l-8.5-6.947a.38.38 0 01-.058-.548L15.412.139a.391.391 0 01.547-.048l-.001-.001zm3.103 14.969a.383.383 0 01-.31.273h-.003l-9.758 2.46 3.776-4.539a1.948 1.948 0 002.409-.475l.002-.003a1.947 1.947 0 00-.25-2.72l-.003-.003a1.945 1.945 0 00-2.739.25l-.003.004a1.938 1.938 0 00-.439 1.422v-.008c.038.396.19.75.422 1.039l-.003-.004-3.786 4.538.615-10.031a.38.38 0 01.212-.331l.003-.001 5.738-2.8 5.757 4.791-1.64 6.138z"
                                fill="#fff"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 3.484h9.118v1.928h-7.19v20.311h14.38v-7.931h1.928v9.859H0V3.484z"
                                fill="#fff"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3.93 21.05H14.44v-1.777H3.93v1.777z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Assign</span
                        >
                      </a>
                    </li>
                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="sendemail"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 23 16"
                              class="sendEmail"
                            >
                              <path
                                fill="#FFF"
                                fill-rule="evenodd"
                                d="M1.248.33c.04.031.07.05.095.075l8.293 7.724c.09.085.184.168.287.238a2.064 2.064 0 002.58-.213L20.807.416c.032-.03.06-.064.09-.097C20.492.082 20.06 0 19.604 0H2.56c-.456 0-.888.082-1.312.33m-.88.838c-.245.4-.364.807-.365 1.245C.002 6.04-.005 9.667.006 13.294c.004 1.433 1.063 2.47 2.515 2.471 5.706.006 11.412.002 17.118.004.459 0 .89-.096 1.28-.329.83-.494 1.238-1.23 1.241-2.181.004-1.023.001-2.046.001-3.069V2.554c0-.488-.09-.952-.366-1.38-.045.039-.082.068-.116.1-2.466 2.297-4.931 4.595-7.398 6.89-.416.388-.81.806-1.269 1.14-1.3.945-2.997.835-4.178-.259C6.26 6.662 3.7 4.268 1.134 1.88c-.25-.234-.502-.466-.766-.71"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Send Email</span
                        >
                      </a>
                    </li>
                    <li
                      class="menu-item hide"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="approvalreport"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              width="22"
                              height="25"
                              viewBox="0 0 12 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="send_approval_report"
                            >
                              <path d="M8 0v4h4L8 0z" fill="#fff"></path>
                              <path
                                d="M8 5c-.551 0-1-.449-1-1V0H1C.449 0 0 .449 0 1v14c0 .552.449 1 1 1h10a1 1 0 001-1V5H8zm-4 9H2v-3h2v3zm3 0H5V9h2v5zm3 0H8V7h2v7z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Send Approval Report</span
                        >
                      </a>
                    </li>
                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="conditionalbranch"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              width="24"
                              height="20"
                              viewBox="0 0 24 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="conditionalBranch"
                            >
                              <path
                                d="M5.748 7.415h2.229l1.332 2.007 2.274-3.052L9.51 3.586H5.748L5.782 0 0 5.814l5.738 5.701.01-4.1zm8.974 3.164L12.4 14.074l3.938 5.925h2.914c.775 0 1.404-.628 1.404-1.404v-1.828c0-.775-.628-1.404-1.404-1.404h-.965l-3.565-4.784zM18.218.001L24 5.816l-5.739 5.7-.008-4.1h-2.229L7.664 20H4.75a1.403 1.403 0 01-1.404-1.404v-1.828c0-.774.63-1.404 1.404-1.404h.964l8.778-11.778h3.761L18.218.001z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Conditional Branch</span
                        >
                      </a>
                    </li>
                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="ifelse"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              width="24"
                              height="22"
                              viewBox="0 0 25 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="binaryCondition"
                            >
                              <path
                                d="M15.823 4.555L11.405 0v2.263H8.962a3.908 3.908 0 00-3.291 1.79L2.85 8.406a.236.236 0 00-.024.04H0v4.187h2.827c.007.013.014.027.023.04l2.82 4.354a3.908 3.908 0 003.292 1.79h2.443v2.495l4.418-4.555-4.418-4.556v2.428H9.108l-2.652-4.09 2.652-4.09h2.297v2.66l4.418-4.555zM20.26 8.28a3.74 3.74 0 100-7.48 3.74 3.74 0 000 7.48zm-1.304-4.375h.668v-.668a.636.636 0 111.271 0v.668h.669a.636.636 0 010 1.272h-.669v.67a.636.636 0 11-1.271 0v-.67h-.668a.635.635 0 110-1.272zM20.26 13.033a3.74 3.74 0 100 7.48 3.74 3.74 0 000-7.48zm1.937 2.726l-1.172 2.379a1.063 1.063 0 01-.83.56c-.038 0-.075.006-.112.006a1.062 1.062 0 01-.817-.384l-.859-1.035a.636.636 0 01.977-.815l.66.792 1.016-2.064a.636.636 0 111.14.561h-.003z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >IF/Else Condition</span
                        >
                      </a>
                    </li>
                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="mergebranches"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              width="19"
                              height="24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="merge"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 6.314V0h4.065v6.133l5.41 2.615 5.41-2.613V0h4.066v6.311a3.797 3.797 0 01-1.737 3.193l-.107.069-5.6 2.71V19h2.296l-4.5 4.5-4.5-4.5h2.64v-6.711l-5.6-2.71-.106-.069A3.796 3.796 0 010 6.314z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Merge Branches</span
                        >
                      </a>
                    </li>
                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="split"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="split"
                            >
                              <path
                                d="M21.373 19.711V13.19a3.796 3.796 0 00-1.737-3.196l-.107-.069-5.599-2.71V0H9.865v7.22l-5.6 2.711L4.16 10a3.796 3.796 0 00-1.737 3.193v6.52H0l4.422 4.29 4.422-4.29H6.487V13.37l5.41-2.613 5.41 2.615v6.343h-2.582l4.423 4.289 4.422-4.29-2.197-.002z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Split</span
                        >
                      </a>
                    </li>
                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="runscript"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              width="24"
                              height="24"
                              fill="#a1a5b7"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 50 50"
                              style="enable-background: new 0 0 50 50"
                              xml:space="preserve"
                            >
                              <g id="Layer_1">
                                <path
                                  d="M39,7c0-3.309-2.691-6-6-6H7C3.691,1,1,3.691,1,7c0,2.967,2.167,5.431,5,5.91V43c0,3.309,2.691,6,6,6h31
                    c3.309,0,6-2.691,6-6v-1H39V7z M3,7c0-2.206,1.794-4,4-4h21.54C27.586,4.063,27,5.462,27,7s0.586,2.937,1.54,4H7
                    C4.794,11,3,9.206,3,7z M8,43V13h25v-2c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4v35v1c0,1.538,0.586,2.937,1.54,4H12
                    C9.794,47,8,45.206,8,43z M46.873,44c-0.444,1.723-2.013,3-3.873,3s-3.429-1.277-3.873-3H46.873z"
                                />
                                <rect x="12" y="17" width="4" height="2" />
                                <rect x="19" y="17" width="14" height="2" />
                                <rect x="12" y="22" width="4" height="2" />
                                <rect x="19" y="22" width="14" height="2" />
                                <rect x="12" y="27" width="4" height="2" />
                                <rect x="19" y="27" width="14" height="2" />
                                <rect x="12" y="32" width="4" height="2" />
                                <rect x="19" y="32" width="14" height="2" />
                                <rect x="12" y="37" width="4" height="2" />
                                <rect x="19" y="37" width="14" height="2" />
                                <rect x="12" y="42" width="4" height="2" />
                                <rect x="19" y="42" width="14" height="2" />
                              </g>
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Run Script</span
                        >
                      </a>
                    </li>
                    <li
                      *ngIf="isConnected"
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        class="drag-drawflow menu-link"
                        [attr.draggable]="ispublished == true ? false : true"
                        (dragstart)="drag($event)"
                        data-node="integrationhub"
                      >
                        <span class="menu-icon">
                          <span class="svg-icon svg-icon-2">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 32 32"
                              style="enable-background: new 0 0 32 32"
                              xml:space="preserve"
                            >
                              <path
                                id="cloud-pak--for-integration_1_"
                                d="M16,31.36c-0.059,0-0.117-0.015-0.171-0.043l-13-7C2.713,24.254,2.64,24.133,2.64,24V8
	c0-0.132,0.073-0.254,0.189-0.317l13-7c0.107-0.058,0.234-0.058,0.342,0l13,7C29.287,7.746,29.36,7.868,29.36,8v16
	c0,0.133-0.073,0.254-0.189,0.317l-13,7C16.117,31.346,16.059,31.36,16,31.36z M3.36,23.785L16,30.591l12.64-6.806V8.215L16,1.409
	L3.36,8.215V23.785z M21.172,24.36c-0.852,0-1.652-0.332-2.255-0.934l-5.171-5.172l0.509-0.51l5.172,5.172
	c0.466,0.467,1.086,0.723,1.745,0.723c1.361,0,2.468-1.106,2.468-2.468c0-0.659-0.256-1.279-0.723-1.745l-2.172-2.172l0.51-0.509
	l2.172,2.171c0.602,0.603,0.934,1.403,0.934,2.255C24.36,22.93,22.93,24.36,21.172,24.36z M10.829,24.36
	c-1.758,0-3.188-1.431-3.188-3.188c0-0.853,0.332-1.653,0.934-2.255l4.171-4.171l0.509,0.509l-4.171,4.172
	c-0.466,0.466-0.723,1.086-0.723,1.745c0,1.361,1.107,2.468,2.469,2.468c0.66,0,1.279-0.256,1.746-0.723l2.171-2.172l0.509,0.51
	l-2.171,2.172C12.481,24.028,11.681,24.36,10.829,24.36z M21,22.36c-0.75,0-1.36-0.61-1.36-1.36c0-0.244,0.065-0.475,0.179-0.673
	l-2.845-2.845c-0.28,0.185-0.614,0.292-0.974,0.292c-0.978,0-1.774-0.796-1.774-1.774c0-0.359,0.107-0.694,0.292-0.974l-2.845-2.845
	C11.474,12.295,11.245,12.36,11,12.36c-0.75,0-1.36-0.61-1.36-1.36S10.25,9.64,11,9.64s1.36,0.61,1.36,1.36
	c0,0.245-0.065,0.474-0.178,0.673l2.845,2.845c0.28-0.185,0.614-0.292,0.974-0.292c0.978,0,1.774,0.796,1.774,1.774
	c0,0.359-0.107,0.694-0.292,0.974l2.845,2.845c0.198-0.113,0.429-0.179,0.673-0.179c0.75,0,1.36,0.61,1.36,1.36
	S21.75,22.36,21,22.36z M21,20.36c-0.353,0-0.64,0.287-0.64,0.64s0.287,0.64,0.64,0.64s0.64-0.287,0.64-0.64S21.353,20.36,21,20.36z
	 M16,14.946c-0.581,0-1.054,0.473-1.054,1.054c0,0.581,0.473,1.054,1.054,1.054c0.581,0,1.054-0.473,1.054-1.054
	S16.581,14.946,16,14.946z M11,10.36c-0.353,0-0.64,0.287-0.64,0.64s0.287,0.64,0.64,0.64s0.64-0.287,0.64-0.64
	S11.353,10.36,11,10.36z M19.255,17.255l-0.51-0.509l4.172-4.171c0.466-0.467,0.723-1.086,0.723-1.746
	c0-1.361-1.106-2.469-2.468-2.469c-0.659,0-1.279,0.257-1.745,0.723l-2.172,2.171l-0.509-0.509l2.171-2.171
	c0.603-0.602,1.402-0.934,2.255-0.934c1.758,0,3.188,1.43,3.188,3.188c0,0.851-0.332,1.652-0.934,2.254L19.255,17.255z
	 M10.746,15.254l-2.171-2.171c-0.602-0.602-0.934-1.402-0.934-2.254c0-1.758,1.43-3.188,3.188-3.188
	c0.852,0,1.652,0.332,2.254,0.934l5.172,5.171l-0.51,0.509l-5.171-5.171c-0.466-0.466-1.086-0.723-1.746-0.723
	c-1.361,0-2.469,1.107-2.469,2.469c0,0.66,0.257,1.279,0.723,1.746l2.171,2.171L10.746,15.254z"
                              />
                              <rect
                                id="_Transparent_Rectangle"
                                style="fill: none"
                                width="32"
                                height="32"
                              />
                            </svg>
                          </span>
                        </span>
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Integration Hub</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::STATIC Aside-->
      </ng-container>
      <!-- end:: Aside Left -->

      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!-- begin:: Header -->

        <!--begin::Container-->
        <div
          id="kt_header"
          class="header cuttopbar"
          [ngClass]="headerCSSClasses"
        >
          <!--begin::Container-->
          <div
            [ngClass]="headerContainerCSSClasses"
            class="d-flex align-items-stretch justify-content-between col-md-12"
          >
            <ng-container *ngIf="loader$ | async as _loader">
              <ngb-progressbar
                class="loading-bar"
                height="3px"
                [value]="_loader"
              ></ngb-progressbar>
            </ng-container>

            <ng-container *ngIf="headerMenuSelfDisplay">
              <!--begin::Header Menu Wrapper-->
              <div
                class="header-menu-wrapper header-menu-wrapper-left"
                id="kt_header_menu_wrapper"
              >
                <ng-container *ngIf="!asideSelfDisplay">
                  <!--begin::Header Logo-->
                  <div class="header-logo">
                    <a onclick="history.back()">
                      <img alt="Logo" [attr.src]="headerLogo" />
                    </a>
                  </div>
                  <!--end::Header Logo-->
                </ng-container>
              </div>
              <!--end::Header Menu Wrapper-->
            </ng-container>

            <ng-container>
              <div class="header-menu-wrapper header-menu-wrapper-left">
                <button
                  class="mt-3 backbutton"
                  mat-raised-button
                  onclick="history.back()"
                >
                  <mat-icon class="back-btn mt-2"> arrow_back_ios </mat-icon>
                </button>
              </div>
              <div
                class="d-flex align-items-stretch justify-content-center flex-lg-grow-1"
              >
                <div class="" id="kt_header_nav">
                  <div class="text-center">
                    <h1>{{ qformname }}: {{ formname }}</h1>
                  </div>
                  <div class="text-center">
                    <h4 *ngIf="isSaving" class="jNewHeader-revisionDate">
                      Saving
                      <div class="saloader">
                        <div class="dot-pulse"></div>
                      </div>
                    </h4>
                    <span
                      *ngIf="lastUpdated != '' && isSaving == false"
                      class="jNewHeader-revisionDate"
                      >Last updated at
                      {{ lastUpdated | date : "MMM dd, yyyy hh:mm:ss a" }}</span
                    >
                  </div>
                </div>
                <div class="d-flex align-items-stretch flex-shrink-0">
                  <div class="d-flex align-items-stretch flex-shrink-0">
                    <div class="d-flex align-items-center ms-1 ms-lg-3"></div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!--end::Container-->
        </div>
        <!--end::Container-->
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          class="content d-flex flex-column flex-column-fluid"
          id="kt_content"
          [ngClass]="contentClasses"
        >
          <!-- begin:: Content Body -->
          <!--begin::Entry-->
          <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div [ngClass]="contentContainerClasses">
              <div class="qform_wrapper">
                <div
                  id="drawflow"
                  (drop)="drop($event)"
                  (dragover)="allowDrop($event)"
                >
                  <div
                    id="btntop"
                    class="bar-buttons hide"
                    *ngIf="!ispublished"
                  >
                    <div
                      *ngIf="!isUpdate"
                      class="btn-export mui-btn--raised"
                      (click)="saveworkflowdata()"
                    >
                      Save
                    </div>
                    <div
                      *ngIf="isUpdate"
                      class="btn-export mui-btn--raised"
                      (click)="saveworkflowdata()"
                    >
                      Update
                    </div>

                    <div
                      class="btn-clear mui-btn--raised"
                      (click)="clearModuleSelected()"
                    >
                      Clear
                    </div>
                  </div>
                </div>
                <div>
                  <div class="btn-lock" id="btnlock">
                    <svg
                      id="lock"
                      class="svg-inline--fa fa-lock fa-w-14"
                      (click)="changeMode('lock')"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="lock"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                      ></path>
                    </svg>
                    <svg
                      id="unlock"
                      class="svg-inline--fa fa-lock-open fa-w-18"
                      (click)="changeMode('unlock')"
                      style="display: block"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="lock-open"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      data-fa-i2svg=""
                      style="display: none"
                    >
                      <path
                        fill="currentColor"
                        d="M423.5 0C339.5.3 272 69.5 272 153.5V224H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48h-48v-71.1c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v80c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-80C576 68 507.5-.3 423.5 0z"
                      ></path>
                    </svg>
                  </div>
                  <div class="bar-zoom">
                    <svg
                      class="svg-inline--fa fa-search-minus fa-w-16 me-2"
                      (click)="zoom_out()"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="search-minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M304 192v32c0 6.6-5.4 12-12 12H124c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z"
                      ></path>
                    </svg>
                    <svg
                      class="svg-inline--fa fa-search fa-w-16 me-2"
                      (click)="zoom_reset()"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="search"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                      ></path>
                    </svg>
                    <svg
                      class="svg-inline--fa fa-search-plus fa-w-16"
                      (click)="zoom_in()"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="search-plus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>

              <!--end::Container-->
            </div>
          </div>
          <!--end::Entry-->
          <!-- end:: Content Body -->
        </div>
        <!-- end:: Content -->
      </div>
      <!--end::Wrapper-->
    </div>
    <!-- end:: Body -->
    <!--end::Page-->
  </div>
</ng-container>

<ng-template #blankLayout>
  <div class="d-flex flex-column flex-root">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<!--end::Main-->
