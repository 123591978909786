export const environment = {
  production: true,
  appVersion: "v717demo1",
  USERDATA_KEY: "authf649fc9a5f55",
  isMockEnabled: true,

 baseUrl: "https://wflow.qforms.ai",
  apiUrl: "https://wflowapi.qforms.ai/api",
 qformUrl: "https://api.qforms.ai",
 qformFrontEndUrl: "https://qforms.ai",
INTEGRATION_HUB_FRONTEND_URL: "https://ihub.qforms.ai",
 INTEGRATION_HUB_BACKEND_URL: "https://ihubapi.qforms.ai",

   /*baseUrl: "https://indqformsai-wflow-frontend.blacksky-50f45a98.centralindia.azurecontainerapps.io",
   apiUrl: "https://indqformsai-wflowapi.blacksky-50f45a98.centralindia.azurecontainerapps.io/api",
   qformUrl: "https://indqformsai-api.blacksky-50f45a98.centralindia.azurecontainerapps.io",
   qformFrontEndUrl: "https://indnewqformsai-frontend.blacksky-50f45a98.centralindia.azurecontainerapps.io",
  INTEGRATION_HUB_FRONTEND_URL: "https://indqformsai-ihub-frontend.blacksky-50f45a98.centralindia.azurecontainerapps.io",
   INTEGRATION_HUB_BACKEND_URL: "https://indqformsai-ihubapi.blacksky-50f45a98.centralindia.azurecontainerapps.io",*/

  servicenow_instanceid: "dev114543",
  servicenow_tablename: "u_mudasir_table_label",
};
export class Config {
  static DATE_FMT = "dd-MM-yyyy";
  static DATE_TIME_FMT = `${Config.DATE_FMT} hh:mma`;
  static secretKey = "drQ8pzjLMqEKZyAhyLqXlxZdyQTsEoiq";
}
