import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";

export interface DialogData {
  header: string;
  content: string;
  postivebtnLabel: string;
  negativeBtnLabel: string;
  id: string;
  workflowid: string;
  name: string;
  mode: string;
}

@Component({
  selector: "app-restart-workflow",
  templateUrl: "./restart-workflow.component.html",
  styleUrls: ["./restart-workflow.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RestartWorkflowComponent implements OnInit {
  responseCode = 0;
  active = true;

  constructor(
    public dialogRef: MatDialogRef<RestartWorkflowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close({ cancel: true, data: {} });
  }
  onSubmit() {
    this.ngxService.start();
    this.dialogRef.close({ cancel: false, fetch: true });
    this.ngxService.stop();
  }
}
